// TODO : REMOVE BEFORE PROD
let params

export function getInURL(s: string) {
  if (!params) {
    const queryString = window.location.search
    params = new URLSearchParams(queryString)
  }

  return params.get(s)
}