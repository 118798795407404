import { Action, Mutation, VuexModule, Module } from 'vuex-class-modules'
import store from '@/store'

export const SLOW_VIDEO_PART = 45

export enum CATEGORIES {
  SLOW = 'slow',
  MAIN = 'main',
  INTRO = 'intro',
  TRANSITION_IN = 'transitionIn',
  TRANSITION_OUT = 'transitionOut',
}

@Module
export class Videos extends VuexModule {
  public loadingProgress = 0
  public introReady = false
  public mainReady = false
  public mousePosition = [0.5, 0.5]
  public step = 0
  public progress = []
  public muted = false
  public playing = false
  public skipping = false

  @Action
  setLoadingProgress(val: number) {
    this._SET_LOADING_PROGRESS(val)
  }

  @Mutation
  private _SET_LOADING_PROGRESS(val: number) {
    this.loadingProgress = val
  }

  @Action
  handleIntroReady() {
    this._SET_INTRO_READY(true)
  }

  @Mutation
  private _SET_INTRO_READY(val: boolean) {
    this.introReady = val
  }

  @Action
  handleMainReady() {
    this._SET_MAIN_READY(true)
  }

  @Mutation
  private _SET_MAIN_READY(val: boolean) {
    this.mainReady = val
  }

  @Action
  setMousePosition(val: number[]) {
    this._SET_MOUSE_POSITION(val)
  }

  @Mutation
  private _SET_MOUSE_POSITION(val: number[]) {
    this.mousePosition = val
  }

  @Action
  incrementStep() {
    this._SET_STEP(this.step + 1)
  }

  @Action
  setStep(val: number) {
    this._SET_STEP(val)
  }

  @Mutation
  private _SET_STEP(val: number) {
    this.step = val
  }

  @Action
  setProgress(val: number[]) {
    this._SET_PROGRESS(val)
  }

  @Action
  setProgressStep(val: number) {
    const progress = [...this.progress]
    progress[this.step] = val
    this._SET_PROGRESS(progress)
  }

  @Mutation
  private _SET_PROGRESS(val: number[]) {
    this.progress = val
  }

  @Action
  setMuted(val: boolean) {
    this._SET_MUTED(val)
  }

  @Mutation
  private _SET_MUTED(val: boolean) {
    this.muted = val
  }

  @Action
  setPlaying(val: boolean) {
    this._SET_PLAYING(val)
  }

  @Mutation
  private _SET_PLAYING(val: boolean) {
    this.playing = val
  }

  @Action
  setSkipping(val: boolean) {
    this._SET_SKIPPING(val)
  }

  @Mutation
  private _SET_SKIPPING(val: boolean) {
    this.skipping = val
  }
}

const videos = new Videos({ store, name: 'videos' })

export default videos
