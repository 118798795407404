






















import Vue from "vue";
import gsap from "gsap/all";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

import Tracking from "@/core/Tracking";
import videos_state from "@/store/modules/videos_state";

@Component
export default class Timeline extends Vue {
  @Prop({ type: Number, default: 0 }) step: number;
  @Prop({ type: Number, default: 0 }) progress: number;

  @Watch("progress")
  onProgressChanged(val: number) {
    gsap.to(this.$refs.timelineProgress, {
      width: `${val * 100}%`,
      duration: 0.1,
      ease: "linear",
    });
  }

  mounted() {
    (this.$refs.timelineProgress as HTMLElement).style.width = `${
      this.progress * 100
    }%`;
  }

  getMousePosition(evt) {
    if (evt.clientX && evt.clientY) {
      return [evt.clientX, evt.clientY];
    }

    if (evt.touches && evt.touches[0]) {
      return [evt.touches[0].clientX, evt.touches[0].clientY];
    }

    return [];
  }

  handleClick(evt) {
    if (this.$refs.timeline) {
      const mousePos = this.getMousePosition(evt);
      const rect = (this.$refs.timeline as HTMLElement).getBoundingClientRect();
      const width = rect.width;
      const x = Math.max(mousePos[0] - rect.x, 0);
      const progress = Math.min(x / width, 1);

      if (this.step > videos_state.step) {
        videos_state.setProgressStep(1);
        videos_state.setStep(this.step);
      } else if (this.step < videos_state.step) {
        videos_state.setProgressStep(0);
        videos_state.setStep(this.step);
      }

      videos_state.setProgressStep(progress);
      videos_state.setSkipping(true);

      Tracking.event("video-interaction", "timeline", "Click on the timeline");
    }
  }

  beforeEnter(el) {
    gsap.set(el, {
      scaleX: 0,
    });
  }

  enter(el) {
    gsap.to(el, {
      scaleX: 1,
      duration: 1,
      ease: "power3.out",
    });
  }

  leave() {
    gsap.to(this.$refs.timeline, {
      scaleX: 0,
      duration: 1,
      ease: "power3.out",
    });
  }
}
