














import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import CTA from "@/components/CTA/index.vue";
import IconCTA from "@/components/IconCTA/index.vue";
import Tracking from "@/core/Tracking";
import site_state, { STEPS } from "@/store/modules/site_state";

@Component({
  components: {
    CTA,
    IconCTA,
  },
})
export default class Outro extends Vue {
  @Prop({ type: Function, required: true }) translate: Function;

  onClick() {
    site_state.setStep(STEPS.INTRO);
    Tracking.event(
      "video-end",
      "relive-experience",
      "Click on Relive the experience"
    );
  }
}
