import site_state from "@/store/modules/site_state";

const Tracking = {

  pageView(name: string) {
    console.debug('[gtm] vpv', name, 'tracking?', site_state.isTracking)
    if (window.dataLayer && site_state.isTracking) {
      window.dataLayer.push({
        event: 'vpv',
        VPV: name
      });
    }
  },

  event(category: string, action: string, label: string) {
    console.debug('[gtm] event', action, 'tracking?', site_state.isTracking)
    if (window.dataLayer && site_state.isTracking) {
      window.dataLayer.push({
        'event': 'eventPush',
        'eventAction': action,
        'eventLabel': label,
        'eventValue': '',
        'eventCategory': category
      })
    }
  },

  videoEvent(action: string, options: { videoPlay?: number, videoLength?: number, videoPercent?: number }) {
    console.debug('[gtm] video event', action, 'tracking?', site_state.isTracking)
    if (window.dataLayer && site_state.isTracking) {
      const { videoPlay, videoLength, videoPercent } = options
      window.dataLayer.push({
        'event': 'gaEvent',
        'eventCategory': 'Main video',
        'eventAction': 'WC_Interactive',
        'eventLabel': action,
        dimension28: videoPlay,
        dimension29: videoLength,
        dimension30: videoPercent,
      })
    }
  }
}


export default Tracking