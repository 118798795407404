import Post from 'nanogl-post'


// import Chromashift  from '@/webgl/glsl/post/chromashift'
import Fetch from 'nanogl-post/effects/fetch'
// import Contrast from 'nanogl-post/effects/contrast'
import Grain from 'nanogl-post/effects/grain'
// import LUT from 'nanogl-post/effects/lut'
// import Saturation from 'nanogl-post/effects/saturation'
// import Sharpen from 'nanogl-post/effects/sharpen'
// import Vignette from 'nanogl-post/effects/vignette'
// import Hejl from 'nanogl-post/effects/hejl'
// import Reinhard from 'nanogl-post/effects/reinhard'
// import Bloom from 'nanogl-post/effects/bloom'
// import Gamma from 'nanogl-post/effects/gamma_tb'
// import Dof from 'nanogl-post/effects/dof'
//import PostDepthDebug     from './post-depth-debug'

// import Quality  from '@/webgl/lib/quality'
 
import glmat from 'gl-matrix'
import Scene  from '@/webgl/main'


/////////////
/////////////////////////////////////////

/////////////////////////////////////////////////////////
///////////
//////////


var vec3 = glmat.vec3;

export default class PostProcess {

  scene      : Scene
  post       : Post
  // chromashift: Chromashift
  // contrast   : Contrast
  // sat        : Saturation
  // sharpen    : Sharpen
  // hejl       : Hejl
  // reinhard   : Reinhard
  // bloom      : Bloom
  // vignette   : Vignette
  // dof        : Dof
  fetch         : Fetch

  _setup: number


  constructor(scene: Scene) {


    this.scene = scene;

    this.post = new Post(scene.gl, false);
    this.post.enabled = true;

    this.post.add(new Fetch())
    this.post.add(new Grain(1, 1))



    this._setup = 0;
/////////////////
///////////////
//////////////

  }




  qualityChange() {
    console.log('quality change')
  }

  preRender() {
  }

  release() {
  }

///////////////
//////////
//////////////////////////////////
/////////////////////////////////
/////////////////////////////////
///
////////////

}
