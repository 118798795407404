import Geometry from './Geometry'

class PlaneGeometry extends Geometry {
  constructor(
    gl,
    { width = 1, height = 1, widthSegments = 1, heightSegments = 1 } = {}
  ) {
    const width_half = width / 2
    const height_half = height / 2

    const gridX = Math.floor(widthSegments)
    const gridY = Math.floor(heightSegments)

    const gridX1 = gridX + 1
    const gridY1 = gridY + 1

    const segment_width = width / gridX
    const segment_height = height / gridY

    let ix, iy

    // Buffers
    const indices = []
    const vertices = []

    // Generate vertices, normals and UVs
    for (iy = 0; iy < gridY1; iy++) {
      const y = iy * segment_height - height_half

      for (ix = 0; ix < gridX1; ix++) {
        const x = ix * segment_width - width_half

        // Vertex
        vertices.push(x, y, 0)

        // Normal
        vertices.push(0, 0, 1)

        // UV
        vertices.push(ix / gridX)
        vertices.push(1 - iy / gridY)
      }
    }

    // Indices
    for (iy = 0; iy < gridY; iy++) {
      for (ix = 0; ix < gridX; ix++) {
        const a = ix + gridX1 * iy
        const b = ix + gridX1 * (iy + 1)
        const c = ix + 1 + gridX1 * (iy + 1)
        const d = ix + 1 + gridX1 * iy

        // Faces
        indices.push(a, b, d)
        indices.push(b, c, d)
      }
    }

    super(gl, vertices, indices)
  }

  // ====== public
  allocate() {
    super.allocate()

    const gl = this.gl

    this.attrib('aPosition', 3, gl.FLOAT)
    this.attrib('aNormal', 3, gl.FLOAT)
    this.attrib('aTexCoord0', 2, gl.FLOAT)
  }

  // ------ getters/setters
  get drawingMethod() {
    return 'drawTriangles'
  }
}

export default PlaneGeometry
