



















import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import CTA from "@/components/CTA/index.vue";
import Title from "@/components/Title/index.vue";
import Tracking from "@/core/Tracking";
import Paragraph from "@/components/Paragraph/index.vue";
import site_state from "@/store/modules/site_state";

@Component({
  components: {
    CTA,
    Title,
    Paragraph,
  },
})
export default class Intro extends Vue {
  @Prop({ type: String, default: "" }) transition: string;
  @Prop({ type: Function, required: true }) translate: Function;

  mounted() {
    this.$emit("introMounted");
  }

  beforeDestroy() {
    this.$emit("introOver");
  }

  onClick() {
    site_state.incrementStep();
    Tracking.event(
      "home",
      "dive-into-performance",
      "Click on Dive into the performance"
    );
  }
}
