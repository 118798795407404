
























































import Vue from "vue";
import gsap from "gsap/all";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

@Component
export default class Loader extends Vue {
  length: number = 0;

  @Prop({ type: Number, default: 0 }) progress: number;

  @Watch("progress")
  onProgressChanged(val: number) {
    const progress = val * this.length;
    const lengthArc = this.length / 4;

    gsap
      .timeline()
      .add("start")
      .to(this.$refs.border1, {
        strokeDashoffset: this.progressOffset(1, progress, lengthArc),
        duration: 0.1,
      })
      .to(this.$refs.border2, {
        strokeDashoffset: this.progressOffset(2, progress, lengthArc),
        duration: 0.1,
      })
      .to(this.$refs.border3, {
        strokeDashoffset: this.progressOffset(3, progress, lengthArc),
        duration: 0.1,
      })
      .to(this.$refs.border4, {
        strokeDashoffset: this.progressOffset(4, progress, lengthArc),
        duration: 0.1,
      });
  }

  get progressPercent() {
    return Math.round(this.progress * 100);
  }

  progressOffset(step, progress, lengthArc) {
    return Math.min(Math.max(lengthArc * step - progress, 0), lengthArc);
  }

  mounted() {
    this.length = (this.$refs.border1 as SVGCircleElement).getTotalLength() * 4;

    gsap.set(
      [
        this.$refs.border1,
        this.$refs.border2,
        this.$refs.border3,
        this.$refs.border4,
      ],
      {
        strokeDasharray: this.length / 4,
        strokeDashoffset: this.length / 4,
      }
    );
  }

  beforeEnter() {
    gsap.set(this.$refs.loaderBg, {
      scale: 1.5,
    });
  }

  enter() {
    gsap.to(this.$refs.loaderBg, {
      scale: 1,
      rotate: "1deg",
      duration: 1,
      ease: "power3.inOut",
    });
  }

  leave(_, done) {
    gsap
      .timeline()
      .add("start")
      .to(
        this.$refs.loaderText,
        {
          opacity: 0,
          duration: 0.8,
          ease: "power3.inOut",
          onComplete: () => {
            done();
          },
        },
        "start"
      )
      .to(
        this.$refs.loaderBg,
        {
          scale: 0,
          rotate: "0deg",
          duration: 1,
          ease: "power3.inOut",
          onComplete: () => {
            done();
          },
        },
        "start"
      );
  }
}
