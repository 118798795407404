







import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export default class IconCTA extends Vue {
  @Prop({ type: String, default: "" }) text: string;
  @Prop({ type: String, default: "arrow_down" }) icon: string;

  get Icon() {
    return require(`!vue-svg-loader!@/assets/icons/${this.icon}.svg`);
  }
}
