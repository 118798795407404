import Program from 'nanogl/program'

const createProgram = (gl, vert, frag) => {
  const prg = new Program(gl)
  const hasHighp = checkHighP(gl)

  // Insert precision definition in the first program line
  var defs = '\n'
  defs += 'precision ' + precision(hasHighp) + ' float;\n'

  // Compile program with vertex and fragment import
  prg.compile(vert, frag, defs)

  // Use program once to avoid freeze if drawn later
  prg.use()

  return prg
}

function checkHighP(gl) {
  const hv = gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.HIGH_FLOAT)
  const hf = gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.HIGH_FLOAT)
  return hf.precision > 0 && hv.precision > 0
}

function precision(hasHighp) {
  return hasHighp ? 'highp' : 'mediump'
}

export default createProgram
