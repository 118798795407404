























































import Vue from "vue";
import gsap from "gsap/all";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import site_state from "@/store/modules/site_state";
import { SplitText } from "@/utils/SplitText";
import { createLinesWrapper } from "@/utils/Splitter";

@Component
export default class ClickHoldCTA extends Vue {
  unwatch: Function[] = [];

  @Prop({ type: String, default: "" }) text: string;
  @Prop({ type: String, default: "outline" }) type: "fill" | "outline";
  @Prop({ type: Boolean, default: false }) richText: boolean;

  mounted() {
    const text = this.$refs.text as HTMLElement;
    const splitter = new SplitText({
      words: 1,
      chars: 0,
      spacing: "0.33em",
    });

    const words = splitter.split(text).words as Array<HTMLElement>;
    text.innerHTML = createLinesWrapper(
      words,
      "click-hold-cta-wrapper__content-wrapper__text-wrapper__text__line"
    );

    this.unwatch.push(
      site_state.$watch(
        (s) => s.skipTutorial,
        () => this.skipTutorial()
      )
    );
  }

  hoverStart() {
    site_state.setIsHovering(true);
    gsap.to([this.$refs.firstCircle, this.$refs.circle], {
      opacity: (i) => 1 - 0.3 * i,
      scale: (i) => 0.8 + 0.3 * i,
      rotate: "1deg",
      duration: 1,
      stagger: 0.05,
      ease: "power3.out",
    });
  }

  hoverStop() {
    site_state.setIsHovering(false);
    if (this.$refs.firstCircle && this.$refs.circle) {
      gsap.to([this.$refs.firstCircle, this.$refs.circle], {
        scale: 1,
        opacity: (i) => (i > 0 ? 0 : 1),
        rotate: "0deg",
        duration: 1,
        stagger: 0.05,
        ease: "power3.out",
      });
    }
  }

  skipTutorial() {
    this.hoverStart();
    this.unwatch.push(
      site_state.$watch(
        (s) => s.isHolding,
        (val) => this.stopHolding(val)
      )
    );
  }

  stopHolding(val) {
    if (site_state.skipTutorial === true && val === false) {
      this.hoverStop();
    }
  }

  async beforeEnter() {
    gsap.set(this.$refs.circle, {
      opacity: 0,
      scale: 1,
    });
    gsap.set(this.$refs.firstCircle, {
      scale: 0,
    });

    await this.$nextTick();
    const lines = (this.$refs.text as HTMLElement).querySelectorAll(
      ".click-hold-cta-wrapper__content-wrapper__text-wrapper__text__line div"
    );

    gsap.set(lines, {
      top:
        site_state.isTablet && !site_state.isLandscape
          ? "1.3rem"
          : site_state.isSmall && !site_state.isLandscape
          ? "0.85rem"
          : "1.1rem",
    });
  }

  async enter() {
    await this.$nextTick();
    const lines = (this.$refs.text as HTMLElement).querySelectorAll(
      ".click-hold-cta-wrapper__content-wrapper__text-wrapper__text__line div"
    );

    gsap
      .timeline()
      .to(this.$refs.firstCircle, {
        scale: 1,
        rotate: "2deg",
        duration: 1,
        ease: "power3.out",
      })
      .to(
        lines,
        {
          top: "0rem",
          duration: 1,
          stagger: 0.2,
          delay: 0.4,
          ease: "power3.out",
        },
        "<"
      );
  }

  leave(_, done) {
    gsap
      .timeline()
      .to(this.$refs.ctaWrapper, {
        opacity: 0,
        duration: 1,
        ease: "power3.out",
      })
      .to(
        this.$refs.contentWrapper,
        {
          width: 0,
          height: 0,
          duration: 1,
          ease: "power3.out",
        },
        "<"
      )
      .to(
        [this.$refs.firstCircle, this.$refs.circle],
        {
          scale: 0,
          rotate: "3deg",
          duration: 1,
          stagger: 0.1,
          ease: "power3.out",
          onComplete: () => {
            done();
          },
        },
        "<"
      );
  }

  beforeDestroy() {
    this.unwatch.forEach((func) => func());
  }
}
