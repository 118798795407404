




import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import Lottie, { AnimationDirection, AnimationItem } from "lottie-web";

@Component
export default class Bodymovin extends Vue {
  animation!: AnimationItem;

  @Prop({ type: String, required: true }) src!: string;
  @Prop({ type: Boolean, default: false }) loop!: boolean;
  @Prop({ type: Boolean, default: false }) autoplay!: boolean;

  mounted() {
    this.animation = Lottie.loadAnimation({
      container: this.$el,
      renderer: "svg",
      loop: this.loop,
      autoplay: this.autoplay,
      animationData: require(`@/assets/bodymovin/${this.src}`),
    });
  }

  beforeDestroy() {
    if (this.animation) {
      this.animation.stop();
      this.animation.destroy();
    }
  }

  setSpeed(speed: number = 1) {
    this.animation.setSpeed(speed);
  }

  setDirection(direction: AnimationDirection = 1) {
    this.animation.setDirection(direction);
  }

  play() {
    this.animation.play();
  }

  pause() {
    this.animation.pause();
  }

  stop() {
    this.animation.stop();
  }

  reset() {
    this.animation.setDirection(1);
    this.animation.setSpeed(1);
    this.animation.goToAndStop(0);
  }
}
