
























import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import Webgl from "@/webgl";
import Scene from "@/webgl/main";
import Intro from "@/components/Intro/Intro.vue";
import Outro from "@/components/Outro/Outro.vue";
import Rotate from "@/components/Rotate/Rotate.vue";
import Loading from "@/components/Loading/Loading.vue";
import Experience from "@/components/Experience/Experience.vue";
import CustomCursor from "@/components/CustomCursor/index.vue";
import videos_state from "@/store/modules/videos_state";
import site_state, { STEPS } from "@/store/modules/site_state";
import { i18n, translate } from "@/core/i18n";
// TODO : REMOVE BEFORE PROD
import { getInURL } from "@/core/UrlParams";

@Component({
  components: {
    Intro,
    Outro,
    Rotate,
    Loading,
    Experience,
    CustomCursor,
  },
})
export default class LaPrairieWhiteCaviar extends Vue {
  public isPlaying: boolean = false;

  webgl: Webgl;
  introTransition: string = "fade";

  get step() {
    return site_state.step;
  }

  get steps() {
    return STEPS;
  }

  get translate() {
    return translate;
  }

  get toRotate() {
    return (
      (site_state.isMobile &&
        site_state.isLandscape &&
        site_state.isSmallHeight) ||
      (site_state.isLandscape !== site_state.startedLandscape &&
        site_state.isMobile &&
        site_state.isTablet)
    );
  }

  get isMobile() {
    return site_state.isMobile;
  }

  get isClickable() {
    return (
      this.step === STEPS.EXPERIENCE ||
      (this.step === STEPS.LOADING && videos_state.mainReady === true)
    );
  }

  @Prop({ type: String, default: "en" }) lang: string;
  @Prop({ type: Boolean, default: false }) tracking: boolean;

  beforeMount() {
    // TODO : REMOVE BEFORE PROD
    i18n.locale = getInURL("lang") || this.lang;
    // TODO : ADD BEFORE PROD
    // i18n.locale = this.lang;

    this.setIsMobile();

    site_state.setIsTracking(this.tracking);

    const video = document.createElement("video");
    site_state.setIsH265(
      video.canPlayType('video/mp4; codecs="hvc1"') === "probably"
    );
  }

  async mounted() {
    window.addEventListener("resize", this.resize);
    this.resize();
    site_state.setStartedLandscape(site_state.isLandscape);

    this.webgl = new Webgl({
      canvas: this.$refs.webglCanvas,
    });
    this.webgl.scene = new Scene(this.webgl.gl, {
      ispostprocessing: false,
      pixelRatio: this.webgl.pixelRatio,
      wrapper: this.$refs.wrapper,
    });

    await this.$nextTick();

    this.webgl.start();
  }

  beforeUnmount() {}

  destroyed() {}

  resize() {
    (document.querySelector(":root") as HTMLElement).style.setProperty(
      "--vh",
      window.innerHeight / 100 + "px"
    );

    this.resizeComponent();

    site_state.setWidthHeight({
      width: window.innerWidth,
      height: window.innerHeight,
    });
    this.setIsMobile();
    this.setIsSmall();
    this.setIsTablet();
    this.setIsSmallHeight();

    this.setOrientation();
  }

  resizeComponent() {
    const wrapper = document.querySelector(
      "laprairie-white-caviar"
    ) as HTMLElement;

    if (wrapper) {
      const parent = (
        wrapper.parentNode as HTMLElement
      ).getBoundingClientRect();
      wrapper.style.width = parent.width + "px";
      wrapper.style.height = parent.height + "px";
    }
  }

  setIsMobile() {
    const isMobile = window.matchMedia(
      "only screen and (hover: none) and (pointer: coarse)"
    );
    site_state.setIsMobile(!!isMobile.matches);
  }

  setIsSmall() {
    const isSmall = window.matchMedia("(max-width: 1023px)");
    site_state.setIsSmall(!!isSmall.matches);
  }

  setIsTablet() {
    const isTablet = window.matchMedia("(min-width: 641px)");
    site_state.setIsTablet(!!isTablet.matches);
  }

  setIsSmallHeight() {
    const smallHeight = window.matchMedia("(max-height: 640px)");
    site_state.setIsSmallHeight(!!smallHeight.matches);
  }

  setOrientation() {
    const orientation = window.matchMedia("(min-aspect-ratio: 4/3)");
    site_state.setIsLandscape(!!orientation.matches);
  }

  introMounted() {
    this.introTransition = "fade";
  }

  introOver() {
    this.introTransition = "fade-delay-2000";
  }
}
