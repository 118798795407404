import raf from "raf";
import MainScene from "./main";

export default class Webgl {

  canvas: HTMLCanvasElement
  pixelRatio: number
  gl: WebGLRenderingContext

  previousTime: number
  rafID: number
  _playing = false
  scene: MainScene | null

  canvasWidth: number
  canvasHeight: number


  constructor({ canvas }) {
    this.canvas = canvas;
    this.pixelRatio = this.getPixelRatio();
    const opts = {
      depth: false,
      antialias: this.pixelRatio < 2,
      stencil: true,
      alpha: false,
      premultipliedAlpha: false,
      preserveDrawingBuffer: false,
      powerPreference: "high-performance",
    };

    this.gl =
      canvas.getContext("webgl2", opts) ||
      canvas.getContext("webgl", opts) ||
      canvas.getContext("experimental-webgl", opts) ||
      canvas.getContext("webgl");

    this.gl.clearColor(0, 0, 0, 1); // en noir pour éviter le carré blanc qui flash sur firefox
    this.gl.clear(this.gl.COLOR_BUFFER_BIT);

    this.canvas = canvas;

    this.previousTime = Date.now();
    this._playing = false;

    this.scene = null;
    this.rafID = -1;

    this.canvasWidth = -1;
    this.canvasHeight = -1;
  }

  _checkSize = () => {
    const rect = (this.canvas.parentNode as HTMLElement).getBoundingClientRect();
    const w = rect.width;
    const h = rect.height;

    if (isNaN(w) || isNaN(h) || w === 0 || h === 0) {
      return false;
    }
    if (w !== this.canvasWidth || h !== this.canvasHeight) {
      this.canvasWidth = w;
      this.canvasHeight = h;
      this.resize();
    }
    return true;
  };

  resize() {
    this.pixelRatio = this.getPixelRatio();
    this.canvas.width = this.canvasWidth * this.pixelRatio;
    this.canvas.height = this.canvasHeight * this.pixelRatio;
    this.canvas.style.width = this.canvasWidth + "px";
    this.canvas.style.height = this.canvasHeight + "px";
    if (this.scene !== null)
      this.scene.resize(this.canvas.width, this.canvas.height, this.pixelRatio);
  }

  start() {
    window.addEventListener("resize", this._checkSize);
    this._checkSize();
    this.render();
  }

  stop() {
    window.removeEventListener("resize", this._checkSize);
    this.stopRender();
  }

  getPixelRatio() {
    return Math.min(window.devicePixelRatio || 1, 2);
  }

  render = () => {
    this.rafID = raf(this.render);
    this.updateRenderer();
  };

  stopRender = () => {
    raf.cancel(this.rafID);
  };

  updateRenderer() {
    const dt = Date.now() - this.previousTime;
    this.previousTime = Date.now();
    const gl = this.gl;
    if (!this.scene?.ispostprocessing) {
      gl.clearColor(0, 0, 0, 1);
      gl.bindFramebuffer(gl.FRAMEBUFFER, null);
      gl.viewport(0, 0, this.canvas.width, this.canvas.height);
      gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
    }

    if (this.scene) this.scene.render(dt);
  }

  dispose() {
    raf.cancel(this.rafID);
  }
}
