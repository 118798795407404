

























import Vue from "vue";
import gsap from "gsap/all";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export default class ControlsButton extends Vue {
  on: boolean = true;

  @Prop({ type: String, default: "bottom" }) mode: "center" | "bottom";
  @Prop({ type: Boolean, default: false }) padded: boolean;
  @Prop({ type: Boolean, default: true }) initState: boolean;
  @Prop({ type: Object, default: () => ({}) }) onProps: object;
  @Prop({ type: Object, default: () => ({}) }) offProps: object;
  @Prop({ type: [Function, Object], required: true }) componentOn:
    | Object
    | Function;
  @Prop({ type: [Function, Object], default: undefined }) componentOff:
    | Object
    | Function;

  mounted() {
    this.on = this.initState;
  }

  handleClick() {
    this.on = !this.on;
    this.$emit("click");
  }

  leave() {
    gsap.to(this.$refs.controlsButton, {
      opacity: 0,
      duration: 1,
      ease: "power1.out",
    });
  }
}
