






import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export default class CTA extends Vue {
  @Prop({ type: String, default: "" }) text: string;
  @Prop({ type: String, default: "fill" }) type: "fill" | "outline";
  @Prop({ type: String, default: "dark-grey" }) color: string;

  onClick() {
    this.$emit("click");
  }
}
