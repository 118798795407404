import gsap from "gsap/all";
import Node from "nanogl-node";
import Camera from "nanogl-camera";
import Texture from 'nanogl/texture'
import Program from "nanogl/program";
import PerspectiveLens from "nanogl-camera/perspective-lens";
import { Howl } from 'howler'

import rectVert from "@/webgl/main/material/common/rect.vert";
import rectFrag from "@/webgl/main/material/common/rect.frag";
import videos_state, { SLOW_VIDEO_PART, CATEGORIES } from "@/store/modules/videos_state";
import PlaneGeometry from "@/webgl/utils/geom/PlaneGeometry";
import createProgram from "@/webgl/utils/createProgram.js";
import site_state, { STEPS } from "@/store/modules/site_state";

const INTRO_SELECTOR = '.intro__content'

export default class Video {
  gl: WebGLRenderingContext
  node: Node
  time: number
  audios: Howl[]
  camera: Camera<PerspectiveLens>
  videos: HTMLVideoElement[]
  wrapper: HTMLElement
  audiosId: number[]
  contexts: CanvasRenderingContext2D[]
  textures: Texture[]
  canvases: HTMLCanvasElement[]
  audiosSrc: string[]
  videosSrc: string[]
  planeGeom: PlaneGeometry
  videoRatio: number
  pixelRatio: number
  initVideos: any
  videoTimes: any
  videoPxSize: number[]
  getCategory: Function
  planeProgram: Program
  noiseTexture: Texture
  onVideoEnded: Function
  currentAudios: any
  currentVideos: any
  getCategoryId: Function
  videoDurations: any
  noiseTextureSrc: string
  onVideoProgress: Function
  ressourcesReady: number
  ressourcesLoaded: number[]
  videoOptions: {
    expInfluence: number,
    introInfluence: number,
    slowVideoInfluence: number
  }

  constructor(gl, opts) {
    this.gl = gl
    this.camera = opts.camera
    this.wrapper = opts.wrapper
    this.pixelRatio = opts.pixelRatio

    this.time = 0
    this.videoRatio = 1
    this.videoOptions = {
      expInfluence: 0,
      introInfluence: 1,
      slowVideoInfluence: 0
    }
    this.videos = []
    this.audios = []
    this.audiosId = []
    this.contexts = []
    this.videoPxSize = [0, 0]
    this.audiosSrc = opts.audiosSrc
    this.videosSrc = opts.videosSrc
    this.ressourcesReady = 0
    this.ressourcesLoaded = [...this.videosSrc.map(() => 0), ...this.audiosSrc.map(() => 0)]
    this.onVideoEnded = opts.onVideoEnded
    this.getCategoryId = opts.getCategoryId
    this.onVideoProgress = opts.onVideoProgress
    this.getCategory = opts.getCategory
    this.videoDurations = {
      [CATEGORIES.MAIN]: [],
      [CATEGORIES.SLOW]: []
    }
    this.noiseTextureSrc = opts.noiseTextureSrc
    this.textures = this.videosSrc.map(() => this.createTexture())
    this.noiseTexture = this.createNoiseTex(this.noiseTextureSrc)
    this.canvases = this.videosSrc.map(() => document.createElement('canvas'))
    this.currentVideos = {
      [CATEGORIES.INTRO]: opts.videoCategories[CATEGORIES.INTRO][0],
      [CATEGORIES.MAIN]: opts.videoCategories[CATEGORIES.MAIN][0],
      [CATEGORIES.SLOW]: opts.videoCategories[CATEGORIES.SLOW][0]
    }
    this.videoTimes = {
      [CATEGORIES.INTRO]: -1,
      [CATEGORIES.MAIN]: -1,
      [CATEGORIES.SLOW]: -1
    }
    this.currentAudios = {
      [CATEGORIES.MAIN]: opts.audioCategories[CATEGORIES.MAIN][0],
      [CATEGORIES.TRANSITION_IN]: opts.audioCategories[CATEGORIES.TRANSITION_IN][0],
      [CATEGORIES.TRANSITION_OUT]: opts.audioCategories[CATEGORIES.TRANSITION_OUT][0],
    }
    this.initVideos = { ...this.currentVideos }
    this.planeProgram = createProgram(this.gl, rectVert(), rectFrag());
    this.onChangeOrientation()

    this.node = new Node();
    this.node.z = 0.5;
    this.node.lookAt(this.camera.position);

    site_state.$watch(s => s.isSmall, () => this.onChangeOrientation())
    site_state.$watch(s => s.isLandscape, () => this.onChangeOrientation())
  }

  // VIDEOS SETUP

  setIntro() {
    this.planeProgram.uTexNoise(this.noiseTexture)

    this.videosSrc
      .forEach((src, id) => {
        if (this.getCategory(id) === CATEGORIES.INTRO) {
          this.setPlayer(src, id)
        }
      })
  }

  setExperience() {
    if (this.videos.length <= 1 && this.audios.length < 1) {
      this.audiosSrc
        .forEach((src, id) => this.setAudio(id, src))

      this.videosSrc
        .forEach((src, id) => {
          if (this.getCategory(id) !== CATEGORIES.INTRO) {
            this.setPlayer(src, id)
          }
        })
    }
  }

  // CALLBACKS

  onChangeOrientation() {
    this.planeProgram.uPortrait(site_state.isSmall && !site_state.isLandscape ? 1 : 0);
  }

  // RESSOURCE LOAD

  ressourceLoadProgress(id, loaded, total) {
    this.ressourcesLoaded[id] = loaded / total

    const ressourcesLoadedXp = this.ressourcesLoaded.filter((_, id) => id !== this.currentVideos[CATEGORIES.INTRO])

    const totalPercent = ressourcesLoadedXp.reduce((acc, val) => acc + val)
    const loadPercent = (totalPercent / ressourcesLoadedXp.length) * 0.99
    site_state.setLoadProgress(loadPercent)
  }

  ressourceReady() {
    this.ressourcesReady += 1

    if (this.ressourcesReady === this.ressourcesLoaded.length) {
      site_state.setLoadProgress(1)
      window.setTimeout(() => {
        videos_state.handleMainReady()
      }, 500)
    }
  }

  // AUDIO

  setAudio(id, src) {
    this.loadAudio(id, src)
  }

  loadAudio(id, src) {
    const onLoaded = (event) => {
      const blob = new Blob(
        [event.target.response],
        { type: "audio/mp3" }
      )

      this.audios[id] = new Howl({
        src: [URL.createObjectURL(blob)],
        loop: this.getCategory(id, true) === CATEGORIES.MAIN ? true : false,
        format: ['mp3']
      });

      this.audios[id].once('load', () => {
        this.ressourceReady()
      })
    }

    const onProgress = (event) => {
      if (event.lengthComputable) {
        this.ressourceLoadProgress(this.videosSrc.length + id, event.loaded, event.total)
      }
    }

    const xhr = new XMLHttpRequest()
    xhr.open("GET", src, true)
    xhr.setRequestHeader('Access-Control-Allow-Headers', '*')
    xhr.responseType = "arraybuffer"

    xhr.addEventListener('load', onLoaded)
    xhr.addEventListener('progress', onProgress)

    xhr.send()
  }

  // DRAWING VIDEO TEXTURES

  createTexture() {
    const texture = new Texture(this.gl)
    texture.setFilter(false)
    texture.clamp()
    return texture
  }

  setPlayer(src, id) {
    const category = this.getCategory(id)
    const videoReady = () => this.videoReady(id, category)
    const updateProgress = () => this.onVideoProgress(id)
    const videoEnded = () => this.onVideoEnded(id)

    this.videos[id] = document.createElement('video')
    this.videos[id].setAttribute('crossOrigin', 'anonymous')
    this.videos[id].setAttribute('playsinline', 'true')
    this.videos[id].addEventListener('loadedmetadata', videoReady.bind(this))

    if (category !== CATEGORIES.INTRO) {
      this.videos[id].volume = 0
      this.videos[id].muted = site_state.isMobile
      this.videos[id].addEventListener('timeupdate', updateProgress)
      this.videos[id].addEventListener('ended', videoEnded)
      this.loadVideo(id, src)
    } else {
      this.videos[id].src = src
      this.videos[id].loop = true
      this.videos[id].muted = true
      this.videos[id].load()
    }
  }

  loadVideo(id, src) {
    const onLoaded = (event) => {
      const blob = new Blob(
        [event.target.response],
        { type: "video/mp4" }
      )

      this.videos[id].src = URL.createObjectURL(blob)
      this.videos[id].load()
    }

    const onProgress = (event) => {
      if (event.lengthComputable) {
        this.ressourceLoadProgress(id, event.loaded, event.total)
      }
    }

    const xhr = new XMLHttpRequest()
    xhr.open("GET", src, true)
    xhr.setRequestHeader('Access-Control-Allow-Headers', '*')
    xhr.responseType = "arraybuffer"

    xhr.addEventListener('load', onLoaded)
    xhr.addEventListener('progress', onProgress)

    xhr.send()
  }

  videoReady(id, category) {
    if (id === this.currentVideos[CATEGORIES.MAIN]) {
      this.videoDurations[category].push(SLOW_VIDEO_PART, this.videos[id].duration - SLOW_VIDEO_PART)
    } else if (category !== CATEGORIES.INTRO) {
      const categoryId = this.getCategoryId(id, category)
      this.videoDurations[category][categoryId] = this.videos[id].duration
    }

    const width = this.videos[id].videoWidth
    const height = this.videos[id].videoHeight
    this.canvases[id].width = width
    this.canvases[id].height = height
    this.contexts[id] = this.canvases[id].getContext('2d')

    if (id === this.currentVideos[CATEGORIES.INTRO]) {
      this.ressourcesLoaded[id] = 1
      videos_state.handleIntroReady()
      this.setSize(width, height)
    }

    this.ressourceReady()
  }

  setSize(width, height) {
    this.videoRatio = width / height

    if (this.planeGeom) {
      this.planeGeom.destroy()
    }
    this.planeGeom = new PlaneGeometry(this.gl, {
      width: this.videoRatio,
      height: 1,
      widthSegments: 240,
      heightSegments: 240
    });

    this.resize(this.pixelRatio)
  }

  resize(pixelRatio) {
    window.setTimeout(() => {
      this.pixelRatio = pixelRatio

      const distance = this.node.z - this.camera.position[2]
      const fov = this.camera.lens._vfov

      const canvasWidth = this.gl.canvas.width
      const canvasHeight = this.gl.canvas.height
      const containerRatio = canvasWidth / canvasHeight

      const height = 2 * Math.tan(fov / 2) * distance
      const width = height * containerRatio
      const scale = Math.max(width / this.videoRatio, height)

      this.node.setScale(scale)
      this.node.invalidate()
      this.node.updateWorldMatrix()

      const pxHeight = canvasHeight * (scale / height)
      const pxWidth = pxHeight * this.videoRatio
      this.videoPxSize = [pxWidth, pxHeight]

      if (site_state.step === STEPS.INTRO) {
        this.setPanel()
      }
    }, 1)
  }

  drawTex(id) {
    if (this.contexts[id] && this.textures[id] && this.canvases[id]) {
      this.contexts[id].drawImage(
        this.videos[id],
        0,
        0,
        this.canvases[id].width,
        this.canvases[id].height
      )

      this.textures[id].fromImage(this.canvases[id])
    }
  }

  updateTextures(mainId, slowId, introId) {
    if (videos_state.mainReady) {
      if (this.videoOptions.slowVideoInfluence < 1 && this.videoTimes[CATEGORIES.MAIN] !== this.videos[mainId].currentTime) {
        this.drawTex(mainId)
        this.videoTimes[CATEGORIES.MAIN] = this.videos[mainId].currentTime
      }
      if (this.videoOptions.slowVideoInfluence > 0 && this.videoTimes[CATEGORIES.SLOW] !== this.videos[slowId].currentTime) {
        this.drawTex(slowId)
        this.videoTimes[CATEGORIES.SLOW] = this.videos[slowId].currentTime
      }
    }

    if ((this.videoOptions.introInfluence > 0 || this.videoOptions.expInfluence < 1) && this.videoTimes[CATEGORIES.INTRO] !== this.videos[introId].currentTime) {
      this.drawTex(introId)
      this.videoTimes[CATEGORIES.INTRO] = this.videos[introId].currentTime
    }
  }

  // NOISE TEXTURE

  createNoiseTex(src) {
    const texture = new Texture(this.gl)
    texture.setFilter(false)

    const img = document.createElement('img')
    img.crossOrigin = "anonymous";
    img.src = src;

    img.onload = () => {
      texture.fromImage(img)
      texture.repeat()
    };

    return texture
  }

  // PANEL DEFINITION

  setPanel() {
    if (site_state.isLandscape) {
      this.planeProgram.uIntroDirection(0)
      return this.planeProgram.uIntroSize(0.5)
    }

    const panelHeight = this.wrapper.querySelector(INTRO_SELECTOR).getBoundingClientRect().height
    const marginBottom = (this.videoPxSize[1] - this.gl.canvas.height) / 2
    const size = (panelHeight * this.pixelRatio + marginBottom) / this.videoPxSize[1]

    this.planeProgram.uIntroDirection(1)
    return this.planeProgram.uIntroSize(size)
  }

  // RESET

  reset() {
    gsap.timeline()
      .to(this.videoOptions, {
        expInfluence: 0,
        duration: 2,
        ease: 'linear',
        onComplete: () => {
          this.videos.forEach((video, id) => {
            if (id === this.currentVideos[CATEGORIES.INTRO]) return
            video.pause()
            video.currentTime = 0
          })
          this.currentVideos = this.initVideos
        }
      })
      .to(this.videoOptions, {
        introInfluence: 1,
        slowVideoInfluence: 0,
        duration: 1,
        ease: 'power2.out',
      })
  }

  // RENDERING

  render() {
    this.time += 0.01
    const M4 = this.camera.getMVP(this.node._wmatrix);
    const mainId = this.currentVideos[CATEGORIES.MAIN]
    const slowId = this.currentVideos[CATEGORIES.SLOW]
    const introId = this.currentVideos[CATEGORIES.INTRO]

    this.updateTextures(mainId, slowId, introId)

    this.planeProgram.use()
    this.planeProgram.uMVP(M4)
    this.planeProgram.uMouse(videos_state.mousePosition)
    this.planeProgram.uSlowInfluence(this.videoOptions.slowVideoInfluence)
    this.planeProgram.uIntroInfluence(this.videoOptions.introInfluence)
    this.planeProgram.uExpInfluence(this.videoOptions.expInfluence)
    this.planeProgram.uTime(this.time)
    if (this.textures[introId] && this.textures[mainId] && this.textures[slowId]) {
      this.planeProgram.uTexIntro(this.textures[introId])
      this.planeProgram.uTex1(this.textures[mainId])
      this.planeProgram.uTex2(this.textures[slowId])
    }

    if (this.planeGeom) {
      this.planeGeom.bind(this.planeProgram);
      this.planeGeom.render();
    }
  }
}