












import Vue from "vue";
import gsap from "gsap/all";
import Component from "vue-class-component";
import site_state from "@/store/modules/site_state";

@Component
export default class CustomCursor extends Vue {
  unwatch: Function[] = [];
  visible: boolean = false;
  mounted() {
    gsap.set(this.$refs.circle, {
      opacity: 0,
      scale: 1,
    });

    this.unwatch.push(
      site_state.$watch(
        (s) => s.isHolding,
        (val) => this.handleHold(val)
      )
    );

    window.addEventListener("mousemove", this.moveCursor);
  }

  beforeDestroy() {
    this.unwatch.forEach((func) => func());
    window.removeEventListener("mousemove", this.moveCursor);
  }

  moveCursor(evt) {
    if (this.visible) {
      gsap.to(this.$refs.cursorWrapper, {
        left: evt.clientX,
        top: evt.clientY,
        duration: 0.06,
        ease: "power2.inOut",
      });
      return;
    }

    gsap.set(this.$refs.cursorWrapper, {
      left: evt.clientX,
      top: evt.clientY,
    });
  }

  handleHold(val) {
    if (!site_state.isHovering) {
      if (val === true) {
        this.visible = true;
        return gsap.to(this.$refs.circle, {
          opacity: (i) => 1 - 0.3 * i,
          scale: (i) => 0.7 + 0.3 * i,
          rotate: "1deg",
          duration: (i) => 1 + 0.3 * i,
          stagger: 0.1,
          ease: "power2.out",
        });
      }

      return gsap.to((this.$refs.circle as Element[]).reverse(), {
        scale: 1,
        rotate: "0deg",
        opacity: 0,
        duration: 1,
        stagger: 0.1,
        ease: "power2.out",
        onComplete: () => {
          this.visible = false;
        },
      });
    }
  }
}
