import { Action, Mutation, VuexModule, Module } from 'vuex-class-modules'

import store from '@/store'

export enum STEPS {
  INTRO = 0,
  LOADING = 1,
  EXPERIENCE = 2,
  OUTRO = 3
}

@Module
export class SiteState extends VuexModule {

  // ====================================================== window size
  width: number = -1
  height: number = -1

  @Action
  setWidthHeight(widthHeight) {
    this._SET_WIDTH_HEIGHT(widthHeight)
  }

  @Mutation
  private _SET_WIDTH_HEIGHT(widthHeight) {
    this.width = widthHeight.width
    this.height = widthHeight.height
  }

  // ====================================================== is the xp shown in mobile
  isMobile: boolean = false

  @Action
  setIsMobile(isMobile: boolean) {
    this._SET_IS_MOBILE(isMobile)
  }

  @Mutation
  private _SET_IS_MOBILE(isMobile: boolean) {
    this.isMobile = isMobile
  }

  // ====================================================== is the xp shown in small screen
  isSmall: boolean = false

  @Action
  setIsSmall(isSmall: boolean) {
    this._SET_IS_SMALL(isSmall)
  }

  @Mutation
  private _SET_IS_SMALL(isSmall: boolean) {
    this.isSmall = isSmall
  }

  // ====================================================== is the xp shown in screen at least as big as tablet
  isTablet: boolean = false

  @Action
  setIsTablet(isTablet: boolean) {
    this._SET_IS_TABLET(isTablet)
  }

  @Mutation
  private _SET_IS_TABLET(isTablet: boolean) {
    this.isTablet = isTablet
  }

  // ====================================================== experience step
  step: number = 0

  @Action
  setStep(step: number) {
    this._SET_STEP(step)
  }

  @Action
  incrementStep() {
    this._SET_STEP(this.step + 1)
  }

  @Mutation
  private _SET_STEP(step: number) {
    this.step = step
  }

  // ====================================================== loading progress
  loadProgress: number = 0

  @Action
  setLoadProgress(progress: number) {
    this._SET_LOAD_PROGRESS(progress)
  }

  @Mutation
  private _SET_LOAD_PROGRESS(progress: number) {
    this.loadProgress = progress
  }

  // ====================================================== is click&holding
  isHolding: boolean = false

  @Action
  setIsHolding(val: boolean) {
    this._SET_IS_HOLDING(val)
  }

  @Mutation
  private _SET_IS_HOLDING(val: boolean) {
    this.isHolding = val
  }

  // ====================================================== is hovering
  isHovering: boolean = false

  @Action
  setIsHovering(val: boolean) {
    this._SET_IS_HOVERING(val)
  }

  @Mutation
  private _SET_IS_HOVERING(val: boolean) {
    this.isHovering = val
  }

  // ====================================================== screen in landscape mode
  isLandscape: boolean = false

  @Action
  setIsLandscape(val: boolean) {
    this._SET_IS_LANDSCAPE(val)
  }

  @Mutation
  private _SET_IS_LANDSCAPE(val: boolean) {
    this.isLandscape = val
  }

  // ====================================================== screen has small height
  isSmallHeight: boolean = false

  @Action
  setIsSmallHeight(val: boolean) {
    this._SET_IS_SMALL_HEIGHT(val)
  }

  @Mutation
  private _SET_IS_SMALL_HEIGHT(val: boolean) {
    this.isSmallHeight = val
  }

  // ====================================================== was xp started with screen in landscape mode
  startedLandscape: boolean = false

  @Action
  setStartedLandscape(val: boolean) {
    this._SET_STARTED_LANDSCAPE(val)
  }

  @Mutation
  private _SET_STARTED_LANDSCAPE(val: boolean) {
    this.startedLandscape = val
  }

  // ====================================================== is tracking activated
  isTracking: boolean = false

  @Action
  setIsTracking(val: boolean) {
    this._SET_IS_TRACKING(val)
  }

  @Mutation
  private _SET_IS_TRACKING(val: boolean) {
    this.isTracking = val
  }

  // ====================================================== skipTutorial
  skipTutorial: boolean = false

  @Action
  setSkipTutorial(val: boolean) {
    this._SET_SKIP_TUTORIAL(val)
  }

  @Mutation
  private _SET_SKIP_TUTORIAL(val: boolean) {
    this.skipTutorial = val
  }

  // ====================================================== skipTutorial
  isH265: boolean = false

  @Action
  setIsH265(val: boolean) {
    this._SET_IS_H265(val)
  }

  @Mutation
  private _SET_IS_H265(val: boolean) {
    this.isH265 = val
  }
}

const site_state = new SiteState({ store, name: 'site_state' })

export default site_state
