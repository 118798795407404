















import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import Loader from "@/components/Loader/index.vue";
import site_state from "@/store/modules/site_state";
import videos_state from "@/store/modules/videos_state";
import ClickHoldCTA from "@/components/ClickHoldCTA/index.vue";

@Component({
  components: {
    Loader,
    ClickHoldCTA,
  },
})
export default class Loading extends Vue {
  get progress() {
    return site_state.loadProgress;
  }

  get ready() {
    return videos_state.mainReady;
  }

  get ctaCategory() {
    return site_state.isMobile ? "mobile" : "desktop";
  }

  @Prop({ type: Function, required: true }) translate: Function;

  leave(el, done) {
    (this.$refs.holdCTA as ClickHoldCTA).leave(el, done);
  }

  beforeDestroy() {
    site_state.setIsHovering(false);
  }
}
