import gsap from "gsap/all"
import Camera from "nanogl-camera";
import PerspectiveLens from "nanogl-camera/perspective-lens";

import Video from "."
import Tracking from "@/core/Tracking";
// @ts-ignore
import noiseTex from '@/assets/images/noise.png'
// @ts-ignore
import mainAudio from '@/assets/audios/main.mp3'
// @ts-ignore
import transitionInAudio from '@/assets/audios/transitionIn.mp3'
// @ts-ignore
import transitionOutAudio from '@/assets/audios/transitionOut.mp3'
// @ts-ignore
import introVideoDesktop from '@/assets/videos/desktop/intro.mp4'
// @ts-ignore
import slowVideoDesktop from '@/assets/videos/desktop/slow.mp4'
// @ts-ignore
import slowVideo2Desktop from '@/assets/videos/desktop/slow2.mp4'
// @ts-ignore
import mainVideoDesktop from '@/assets/videos/desktop/main.mp4'
// @ts-ignore
import introVideoDesktopH265 from '@/assets/videos/desktop/intro-h265.mp4'
// @ts-ignore
import slowVideoDesktopH265 from '@/assets/videos/desktop/slow-h265.mp4'
// @ts-ignore
import slowVideo2DesktopH265 from '@/assets/videos/desktop/slow2-h265.mp4'
// @ts-ignore
import mainVideoDesktopH265 from '@/assets/videos/desktop/main-h265.mp4'
// @ts-ignore
import introVideoTablet from '@/assets/videos/tablet/intro.mp4'
// @ts-ignore
import slowVideoTablet from '@/assets/videos/tablet/slow.mp4'
// @ts-ignore
import slowVideo2Tablet from '@/assets/videos/tablet/slow2.mp4'
// @ts-ignore
import mainVideoTablet from '@/assets/videos/tablet/main.mp4'
// @ts-ignore
import introVideoTabletH265 from '@/assets/videos/tablet/intro-h265.mp4'
// @ts-ignore
import slowVideoTabletH265 from '@/assets/videos/tablet/slow-h265.mp4'
// @ts-ignore
import slowVideo2TabletH265 from '@/assets/videos/tablet/slow2-h265.mp4'
// @ts-ignore
import mainVideoTabletH265 from '@/assets/videos/tablet/main-h265.mp4'
// @ts-ignore
import introVideoMobile from '@/assets/videos/mobile/intro.mp4'
// @ts-ignore
import slowVideoMobile from '@/assets/videos/mobile/slow.mp4'
// @ts-ignore
import slowVideo2Mobile from '@/assets/videos/mobile/slow2.mp4'
// @ts-ignore
import mainVideoMobile from '@/assets/videos/mobile/main.mp4'
// @ts-ignore
import introVideoMobileH265 from '@/assets/videos/mobile/intro-h265.mp4'
// @ts-ignore
import slowVideoMobileH265 from '@/assets/videos/mobile/slow-h265.mp4'
// @ts-ignore
import slowVideo2MobileH265 from '@/assets/videos/mobile/slow2-h265.mp4'
// @ts-ignore
import mainVideoMobileH265 from '@/assets/videos/mobile/main-h265.mp4'
import site_state, { STEPS } from "@/store/modules/site_state";
import videos_state, { SLOW_VIDEO_PART, CATEGORIES } from "@/store/modules/videos_state";

const CLICK_HOLD_CTA_SELECTOR = '.click-hold-cta-wrapper__circles__circle-wrapper__circle.first'
const VIDEO_VOLUME = 1
const WAIT_BEFORE_SKIP = 3

const DESKTOP_VIDEOS = [introVideoDesktop, mainVideoDesktop, slowVideoDesktop, slowVideo2Desktop]
const MOBILE_VIDEOS = [introVideoMobile, mainVideoMobile, slowVideoMobile, slowVideo2Mobile]
const TABLET_VIDEOS = [introVideoTablet, mainVideoTablet, slowVideoTablet, slowVideo2Tablet]
const DESKTOP_VIDEOS_H265 = [introVideoDesktopH265, mainVideoDesktopH265, slowVideoDesktopH265, slowVideo2DesktopH265]
const MOBILE_VIDEOS_H265 = [introVideoMobileH265, mainVideoMobileH265, slowVideoMobileH265, slowVideo2MobileH265]
const TABLET_VIDEOS_H265 = [introVideoTabletH265, mainVideoTabletH265, slowVideoTabletH265, slowVideo2TabletH265]

const DESKTOP_AUDIOS = [transitionInAudio, transitionOutAudio]
const MOBILE_AUDIOS = [mainAudio, transitionInAudio, transitionOutAudio]

export default class VideoController {
  gl: WebGLRenderingContext
  slow: boolean
  video: Video
  camera: Camera<PerspectiveLens>
  started: boolean
  wrapper: HTMLElement
  progress: number[]
  audiosSrc: string[]
  videosSrc: string[]
  pixelRatio: number
  trackingOnce: any
  introVideoSrc: string
  mousePosition: number[]
  noiseTextureSrc: string
  videoCategories: any
  audioCategories: any
  bindedOnMouseUp: EventListener
  bindedOnMouseDown: EventListener
  bindedOnMouseMove: EventListener

  constructor(gl, opts) {
    this.gl = gl
    this.wrapper = opts.wrapper
    this.pixelRatio = opts.pixelRatio
    this.started = false
    this.mousePosition = [0, 0]
    this.audiosSrc = site_state.isMobile
      ? MOBILE_AUDIOS
      : DESKTOP_AUDIOS
    this.videosSrc = this.getVideosSrc()
    this.noiseTextureSrc = noiseTex
    this.videoCategories = {
      [CATEGORIES.INTRO]: [0],
      [CATEGORIES.MAIN]: [1],
      [CATEGORIES.SLOW]: [2, 3]
    }
    const videoAudiosLength = this.audiosSrc.length - DESKTOP_AUDIOS.length
    this.audioCategories = {
      [CATEGORIES.MAIN]: site_state.isMobile ? [0] : [],
      [CATEGORIES.TRANSITION_IN]: [videoAudiosLength],
      [CATEGORIES.TRANSITION_OUT]: [videoAudiosLength + 1],
    }
    this.video = new Video(gl, {
      camera: opts.camera,
      wrapper: this.wrapper,
      audiosSrc: this.audiosSrc,
      videosSrc: this.videosSrc,
      getCategory: this.getCategory.bind(this),
      onVideoEnded: this.videoEnded.bind(this),
      getCategoryId: this.getCategoryId.bind(this),
      onVideoProgress: this.updateProgress.bind(this),
      videoCategories: this.videoCategories,
      audioCategories: this.audioCategories,
      noiseTextureSrc: this.noiseTextureSrc,
    })
    this.trackingOnce = {}

    videos_state.setProgress(this.videoCategories[CATEGORIES.SLOW].map(() => 0))
    videos_state.$watch(s => s.skipping, () => this.onChangeProgress())
    videos_state.$watch(s => s.mainReady, () => this.onRessourcesReady())
    videos_state.$watch(s => s.playing, () => this.onTogglePlaying())
    videos_state.$watch(s => s.introReady, () => this.startIntro())
    videos_state.$watch(s => s.step, (curr) => this.onChangeVideoStep(curr))
    videos_state.$watch(s => s.muted, () => this.onChangeMuted())
    site_state.$watch(s => s.step, () => this.onChangeStep())

    this.bindedOnMouseDown = this.onMouseDown.bind(this)
    this.bindedOnMouseUp = this.onMouseUp.bind(this)
    this.bindedOnMouseMove = this.onMouseMove.bind(this)

    this.video.setIntro()
    Tracking.pageView('localecode/home')
  }

  // INIT SRC

  getVideosSrc() {
    if (site_state.isMobile && site_state.isLandscape && !site_state.isSmallHeight && site_state.isH265) {
      return TABLET_VIDEOS_H265
    }

    if (site_state.isMobile && site_state.isLandscape && !site_state.isSmallHeight && !site_state.isH265) {
      return TABLET_VIDEOS
    }

    if (site_state.isMobile && site_state.isH265) {
      return MOBILE_VIDEOS_H265
    }

    if (site_state.isMobile && !site_state.isH265) {
      return MOBILE_VIDEOS
    }

    if (site_state.isH265) {
      return DESKTOP_VIDEOS_H265
    }

    return DESKTOP_VIDEOS
  }

  // CALLBACKS

  startIntro() {
    if (videos_state.introReady === true && site_state.step === STEPS.INTRO) {
      this.videoPlay(this.video.currentVideos[CATEGORIES.INTRO])

      if (this.started !== true) {
        this.started = true
        return this.setMouseHover()
      }

      // WAIT FOR TRANSITION
      window.setTimeout(() => {
        this.setMouseHover()
      }, 2000)
    }
  }

  onChangeStep() {
    if (site_state.step === STEPS.INTRO) {
      this.video.setIntro()
      this.startIntro()
      this.reset()
      Tracking.pageView('localecode/home')
    } else if (site_state.step === STEPS.LOADING) {
      this.transitionIntro()
      this.removeMouseHover()

      if (videos_state.mainReady) {
        this.onRessourcesReady()
      }

      // WAIT FOR TRANSITION
      window.setTimeout(() => {
        this.video.setExperience()
      }, 1000)
    } else if (site_state.step === STEPS.EXPERIENCE) {
      this.setLeaveTracking()
      this.removeXpMouseControls()
      this.setXpMouseControls()
    } else if (site_state.step === STEPS.OUTRO) {
      this.audioStop(this.video.currentAudios[CATEGORIES.MAIN])
      this.removeXpMouseControls()
      Tracking.pageView('ending')
    }
  }

  onChangeMuted() {
    this.videoCategories[CATEGORIES.MAIN].forEach(this.videoChangeMuted.bind(this))
    this.videoCategories[CATEGORIES.SLOW].forEach(this.videoChangeMuted.bind(this))
    this.video.audios.forEach(this.audioChangeMuted.bind(this))
  }

  async onRessourcesReady() {
    try {
      Tracking.pageView('tuto')
      await this.videoPlay(this.video.currentVideos[CATEGORIES.MAIN], true)
      this.videoPause(this.video.currentVideos[CATEGORIES.MAIN])

      this.setXpMouseControls()
      window.setTimeout(() => {
        this.skipTutorial()
      }, WAIT_BEFORE_SKIP * 1000)
    } catch (err) {
      this.setXpMouseControls()
    }
  }

  onTogglePlaying() {
    if (site_state.step > STEPS.LOADING) {
      if (videos_state.playing) {
        if (this.video.videoOptions.slowVideoInfluence > 0) {
          this.videoPlay(this.video.currentVideos[CATEGORIES.SLOW])
        }
        if (this.video.videoOptions.slowVideoInfluence < 1) {
          this.videoPlay(this.video.currentVideos[CATEGORIES.MAIN])
        }
        if (this.video.currentAudios[CATEGORIES.MAIN] !== undefined) {
          this.audioPlay(this.video.currentAudios[CATEGORIES.MAIN])
        }
      } else {
        this.videoPause(this.video.currentVideos[CATEGORIES.MAIN])
        this.videoPause(this.video.currentVideos[CATEGORIES.SLOW])
        if (this.video.currentAudios[CATEGORIES.MAIN] !== undefined) {
          this.audioPause(this.video.currentAudios[CATEGORIES.MAIN])
        }
      }
    }
  }

  onChangeProgress() {
    if (videos_state.skipping === true) {
      this.videoPlayOrSetProgress(this.video.currentVideos[CATEGORIES.SLOW])
      this.videoPlayOrSetProgress(this.video.currentVideos[CATEGORIES.MAIN])
      videos_state.setSkipping(false)
    }
  }

  // SKIP TUTORIAL

  skipTutorial() {
    if (site_state.step === STEPS.LOADING) {
      site_state.setSkipTutorial(true)
      window.setTimeout(() => {
        this.showExp()
      }, 1)
    }
  }

  // MOUSE CONTROLS

  setMouseHover() {
    document.addEventListener('mousemove', this.bindedOnMouseMove)
    document.addEventListener('touchmove', this.bindedOnMouseMove)
  }

  removeMouseHover() {
    videos_state.setMousePosition([0.5, 0.5])
    document.removeEventListener('mousemove', this.bindedOnMouseMove)
    document.removeEventListener('touchmove', this.bindedOnMouseMove)
  }

  setXpMouseControls() {
    setTimeout(() => {
      const clickable = [
        this.gl.canvas,
        ...this.wrapper.querySelectorAll(CLICK_HOLD_CTA_SELECTOR)
      ]
      clickable.forEach((target) => {
        target.addEventListener('mousedown', this.bindedOnMouseDown)
        target.addEventListener('mouseup', this.bindedOnMouseUp)
        target.addEventListener('touchstart', this.bindedOnMouseDown)
        target.addEventListener('touchend', this.bindedOnMouseUp)
      })
    }, 1)
  }

  removeXpMouseControls() {
    const clickable = [this.gl.canvas, ...this.wrapper.querySelectorAll(CLICK_HOLD_CTA_SELECTOR)]
    clickable.forEach((target) => {
      target.removeEventListener('mousedown', this.bindedOnMouseDown)
      target.removeEventListener('mouseup', this.bindedOnMouseUp)
      target.removeEventListener('touchstart', this.bindedOnMouseDown)
      target.removeEventListener('touchend', this.bindedOnMouseUp)
    })
  }

  getMousePosition(evt) {
    if (evt.clientX && evt.clientY) {
      return [evt.clientX, evt.clientY]
    }

    if (evt.touches && evt.touches[0]) {
      return [evt.touches[0].clientX, evt.touches[0].clientY]
    }

    return []
  }

  onMouseMove(evt) {
    const marginLeft = (this.video.videoPxSize[0] - this.gl.canvas.width) / 2
    const marginTop = (this.video.videoPxSize[1] - this.gl.canvas.height) / 2

    const [mouseX, mouseY] = this.getMousePosition(evt)

    if (mouseX && mouseY) {
      const x = (mouseX * this.pixelRatio + marginLeft) / this.video.videoPxSize[0]
      const y = (mouseY * this.pixelRatio + marginTop) / this.video.videoPxSize[1]

      videos_state.setMousePosition([x, y])
    }
  }


  onMouseDown(evt) {
    evt.preventDefault()

    const action = evt.target === this.gl.canvas ? 'click-and-hold-canvas' : 'click-and-hold-button'
    const label = evt.target === this.gl.canvas ? 'Click on canvas' : 'Click on Click and hold'

    if (site_state.step === STEPS.EXPERIENCE) {
      Tracking.event('video-interaction', action, `${label} to switch videos`)

      const options = this.getTrackingVideoOptions()
      this.trackOnce('Start', true, options)
      Tracking.videoEvent('Click', options)

      return this.showSlow()
    }

    Tracking.event('tuto', action, `${label} to start the video`)
    return this.showExp()
  }

  onMouseUp() {
    if (site_state.step === STEPS.EXPERIENCE) {
      return this.hideSlow()
    }
    return this.hideExp()
  }

  // VIDEO UTILS

  getCategory(id, isAudio = false) {
    const categories = isAudio ? this.audioCategories : this.videoCategories
    return Object.keys(categories).reduce((acc, cat) => {
      return categories[cat].includes(id) ? cat : acc
    }, CATEGORIES.INTRO)
  }

  getCategoryId(id, category) {
    return this.videoCategories[category].indexOf(id)
  }

  getVideoProgressTime(id) {
    const category = this.getCategory(id)
    const totalDuration = this.video.videoDurations[category][videos_state.step]
    const prevStepTime = this.getPrevStepTime(category)

    const time = videos_state.progress[videos_state.step] * totalDuration + prevStepTime

    return time
  }

  getPrevStepTime(category) {
    return category === CATEGORIES.MAIN && videos_state.step > 0 ? SLOW_VIDEO_PART * videos_state.step : 0
  }

  // VIDEO CONTROLS

  async videoPlay(id, testing = false) {
    try {
      await this.video.videos[id].play()
    } catch (err) {
      if (testing) {
        throw err
      }
    }
  }

  videoPause(id) {
    this.video.videos[id].pause()
  }

  videoStop(id) {
    this.video.videos[id].currentTime = this.video.videos[id].duration
    this.video.videos[id].pause()
  }

  videoReset(id) {
    this.video.videos[id].currentTime = 0
    this.video.videos[id].pause()
  }

  videoSetProgress(id) {
    const time = this.getVideoProgressTime(id)
    this.video.videos[id].currentTime = time
  }

  videoPlayFromProgress(id) {
    this.videoSetProgress(id)
    this.videoPlay(id)
  }

  videoPlayOrSetProgress(id) {
    if (videos_state.playing) {
      this.videoPlayFromProgress(id)
    } else {
      this.videoSetProgress(id)
    }
  }

  videoChangeMuted(id) {
    this.video.videos[id].muted = videos_state.muted
  }

  // VIDEO PROGRESS

  updateProgress(id) {
    const category = this.getCategory(id)

    const mainProgress = id === this.video.currentVideos[CATEGORIES.MAIN] && (
      (site_state.isHolding === false && site_state.step === STEPS.EXPERIENCE)
      || (site_state.isHolding === true && site_state.step === STEPS.LOADING)
    )
    const slowProgress = site_state.isHolding === true && id === this.video.currentVideos[CATEGORIES.SLOW]

    if (
      (mainProgress || slowProgress)
      && videos_state.step < videos_state.progress.length
      && videos_state.mainReady === true
      && videos_state.playing === true
    ) {
      const prevStepTime = this.getPrevStepTime(category)
      const progress = (this.video.videos[id].currentTime - prevStepTime) / this.video.videoDurations[category][videos_state.step]
      videos_state.setProgressStep(progress)

      this.checkEnded()
    }

    if (site_state.step === STEPS.EXPERIENCE && videos_state.step === 0
      && id === this.video.currentVideos[CATEGORIES.MAIN]
      && this.video.videos[id].currentTime >= SLOW_VIDEO_PART) {
      this.videoEnded(id)
    }
  }

  checkEnded() {
    const step = videos_state.step
    if (step === videos_state.progress.length - 1 && videos_state.progress[step] > 0.8) {
      site_state.incrementStep()
    }
  }

  videoEnded(id) {
    if (id === this.video.currentVideos[CATEGORIES.MAIN] && videos_state.step !== 0) {
      const options = this.getTrackingVideoOptions()
      Tracking.videoEvent('Complete', options)
    }

    if (
      ((id === this.video.currentVideos[CATEGORIES.MAIN] && site_state.isHolding === false) || (id === this.video.currentVideos[CATEGORIES.SLOW] && site_state.isHolding === true))
      && site_state.step === STEPS.EXPERIENCE
    ) {
      videos_state.setProgressStep(1)
      videos_state.incrementStep()
    }
  }

  onChangeVideoStep(currentStep) {
    if (site_state.step === STEPS.EXPERIENCE) {
      const nextSlowId = this.videoCategories[CATEGORIES.SLOW][currentStep]
      this.videoStop(this.video.currentVideos[CATEGORIES.SLOW])

      if (nextSlowId) {
        this.video.currentVideos[CATEGORIES.SLOW] = nextSlowId

        this.videoPlayOrSetProgress(nextSlowId)
        this.videoPlayOrSetProgress(this.video.currentVideos[CATEGORIES.MAIN])
      } else {
        this.videoStop(this.video.currentVideos[CATEGORIES.MAIN])
      }
    }
  }

  // AUDIO TRANSITIONS

  audioFade(
    { videoToPlay, videoToPause }:
      { videoToPlay?: number, videoToPause?: number }
  ) {
    if (videoToPlay) {
      gsap.fromTo(this.video.videos[videoToPlay], {
        volume: 0,
      }, {
        volume: VIDEO_VOLUME,
        duration: 1,
        ease: 'power2.in',
      })
    }

    if (videoToPause) {
      gsap.to(this.video.videos[videoToPause], {
        volume: 0,
        duration: 1,
        ease: 'power2.out',
      })
    }
  }

  audioPlay(id) {
    const audio = this.video.audios[id]

    if (!audio)
      return

    if (!this.video.audiosId[id]) {
      const audioId = audio.play()
      this.video.audiosId[id] = audioId
      return
    }

    audio.play(this.video.audiosId[id])
  }

  audioPause(id) {
    const audio = this.video.audios[id]
    if (audio) {
      audio.pause()
    }
  }

  audioToggleVolume(id, muted?) {
    const audio = this.video.audios[id]
    const audioId = this.video.audiosId[id]
    if (audio && audioId) {
      const from = muted ? 0 : VIDEO_VOLUME
      const to = muted ? VIDEO_VOLUME : 0
      audio.fade(from, to, 1000, audioId)
    }
  }

  audioStop(id) {
    const audio = this.video.audios[id]
    if (audio) {
      audio.stop()
    }
  }

  audioChangeMuted(audio) {
    audio.mute(videos_state.muted)
  }

  // VIDEO TRANSITIONS

  showSlow() {
    if (videos_state.playing) {
      const videoToPlay = this.video.currentVideos[CATEGORIES.SLOW]
      const videoToPause = this.video.currentVideos[CATEGORIES.MAIN]

      site_state.setIsHolding(true)
      this.videoPlayFromProgress(videoToPlay)
      this.audioFade({ videoToPlay, videoToPause })
      this.audioStop(this.video.currentAudios[CATEGORIES.TRANSITION_OUT])
      this.audioPlay(this.video.currentAudios[CATEGORIES.TRANSITION_IN])
      this.audioToggleVolume(this.video.currentAudios[CATEGORIES.MAIN])

      gsap.to(this.video.videoOptions, {
        slowVideoInfluence: 1,
        duration: 2,
        ease: 'linear',
      })

      this.trackOnce('capsule-video')
    }
  }

  hideSlow() {
    if (site_state.isHolding === true && videos_state.playing) {
      const videoToPlay = this.video.currentVideos[CATEGORIES.MAIN]
      const videoToPause = this.video.currentVideos[CATEGORIES.SLOW]

      site_state.setIsHolding(false)
      this.videoPlayFromProgress(videoToPlay)
      this.audioFade({ videoToPlay, videoToPause })
      this.audioStop(this.video.currentAudios[CATEGORIES.TRANSITION_IN])
      this.audioPlay(this.video.currentAudios[CATEGORIES.TRANSITION_OUT])
      this.audioToggleVolume(this.video.currentAudios[CATEGORIES.MAIN], true)

      gsap.to(this.video.videoOptions, {
        slowVideoInfluence: 0,
        duration: 2,
        ease: 'linear',
      })

      this.trackOnce('main-video')
    }
  }

  showExp() {
    if (!site_state.isHolding) {
      const videoToPlay = this.video.currentVideos[CATEGORIES.MAIN]
      const videoToStop = this.video.currentVideos[CATEGORIES.INTRO]

      site_state.setIsHolding(true)
      videos_state.setPlaying(true)

      this.videoPlayFromProgress(this.video.currentVideos[CATEGORIES.MAIN])
      this.videoPlayFromProgress(this.video.currentVideos[CATEGORIES.SLOW])

      if (this.video.currentAudios[CATEGORIES.MAIN] === undefined) {
        this.audioFade({ videoToPlay })
      } else {
        this.audioPlay(this.video.currentAudios[CATEGORIES.MAIN])
      }

      gsap.to(this.video.videoOptions, {
        expInfluence: 1,
        duration: 2,
        ease: 'linear',
        onComplete: () => {
          if (site_state.isHolding === true && site_state.step === STEPS.LOADING) {
            site_state.incrementStep();
            site_state.setIsHolding(false)
            this.videoStop(videoToStop)
            this.trackOnce('main-video')
          }
        }
      })
    }
  }

  hideExp() {
    const videoToPause = this.video.currentVideos[CATEGORIES.MAIN]

    site_state.setIsHolding(false)
    videos_state.setPlaying(false)

    if (this.video.currentAudios[CATEGORIES.MAIN] === undefined) {
      this.audioFade({ videoToPause })
    } else {
      this.audioStop(this.video.currentAudios[CATEGORIES.MAIN])
    }

    gsap.to(this.video.videoOptions, {
      expInfluence: 0,
      duration: 2,
      ease: 'linear',
      onComplete: () => {
        if (site_state.isHolding === false && site_state.step === STEPS.LOADING) {
          this.videoReset(videoToPause)
          this.videoReset(this.video.currentVideos[CATEGORIES.SLOW])
        }
      }
    })
  }

  // STEPS TRANSITIONS

  transitionIntro() {
    gsap.to(this.video.videoOptions, {
      introInfluence: 0,
      duration: 1,
      ease: 'power2.out'
    })
  }

  // TRACKING

  trackOnce(name: string, video: boolean = false, options = {}) {
    if (!this.trackingOnce[name]) {
      if (video) {
        Tracking.videoEvent(name, options)
      } else {
        Tracking.pageView(name)
      }
      this.trackingOnce[name] = true
    }
  }

  getTrackingVideoOptions() {
    const mainVideo = this.video.videos[this.video.currentVideos[CATEGORIES.MAIN]]

    if (mainVideo) {
      const currentTime = mainVideo.currentTime * 1000
      const duration = mainVideo.duration * 1000
      const percent = (currentTime / duration) * 100

      return {
        videoPlay: this.limitDecimals(currentTime),
        videoLength: this.limitDecimals(duration),
        videoPercent: this.limitDecimals(percent)
      }
    }

    return {}
  }

  limitDecimals(number: number) {
    return parseFloat(number.toFixed(2))
  }

  setLeaveTracking() {
    window.onbeforeunload = () => {
      const options = this.getTrackingVideoOptions()
      Tracking.videoEvent('Play', options)
    }
  }

  // RESET

  reset() {
    const options = this.getTrackingVideoOptions()
    Tracking.videoEvent('Play', options)

    videos_state.setStep(0)
    videos_state.setProgress(this.videoCategories[CATEGORIES.SLOW].map(() => 0))
    site_state.setIsHolding(false)
    this.video.reset()
    this.trackingOnce = {}
  }

  // RESIZE

  resize(pixelRatio) {
    this.video.resize(pixelRatio)
  }

  // RENDER

  render() {
    this.video.render()
  }
}