












































import Vue from "vue";
import gsap from "gsap/all";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import Play from "!vue-svg-loader!@/assets/icons/play.svg";
import Pause from "!vue-svg-loader!@/assets/icons/pause.svg";
import Tracking from "@/core/Tracking";
import SoundOff from "!vue-svg-loader!@/assets/icons/sound_off.svg";
import Timeline from "@/components/Timeline/index.vue";
import Bodymovin from "@/components/Bodymovin/index.vue";
import site_state from "@/store/modules/site_state";
import videos_state from "@/store/modules/videos_state";
import ClickHoldCTA from "@/components/ClickHoldCTA/index.vue";
import ControlsButton from "@/components/ControlsButton/index.vue";

@Component({
  components: {
    Timeline,
    ClickHoldCTA,
    ControlsButton,
  },
})
export default class Experience extends Vue {
  unwatch: Function;
  onProps: object = {
    src: "sound.json",
    loop: true,
    autoplay: true,
    style: {
      maxHeight: "100%",
    },
  };

  get videosProgress() {
    return videos_state.progress;
  }

  get bodymovin() {
    return Bodymovin;
  }

  get play() {
    return Play;
  }

  get pause() {
    return Pause;
  }

  get soundOff() {
    return SoundOff;
  }

  get muted() {
    return videos_state.muted;
  }

  get playing() {
    return videos_state.playing;
  }

  get ctaCategory() {
    return site_state.isMobile ? "mobile" : "desktop";
  }

  @Prop({ type: Function, required: true }) translate: Function;

  mounted() {
    this.unwatch = site_state.$watch(
      (s) => s.isHolding,
      (val) => this.toggleShow(val)
    );
  }

  beforeDestroy() {
    this.unwatch();
    site_state.setIsHovering(false);
  }

  toggleShow(val) {
    if (val === true) {
      return gsap.to(this.$refs.experience, {
        opacity: 0,
        duration: 0.5,
        ease: "power2.out",
      });
    }

    return gsap.to(this.$refs.experience, {
      opacity: 1,
      duration: 0.5,
      delay: 0.5,
      ease: "power2.in",
    });
  }

  onSoundClick() {
    videos_state.setMuted(!videos_state.muted);
    Tracking.event("video-interaction", "mute", "Click on mute");
  }

  onVideoControlClick() {
    videos_state.setPlaying(!videos_state.playing);
    Tracking.event("video-interaction", "play-pause", "Click on play/pause");
  }

  leave(el, done) {
    (this.$refs.playButton as ControlsButton).leave();
    (this.$refs.soundButton as ControlsButton).leave();
    (this.$refs.holdCTA as ClickHoldCTA).leave(el, done);
    (this.$refs.timelines as Timeline[]).forEach((timeline) => {
      timeline.leave();
    });
  }
}
