







import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import Title from "@/components/Title/index.vue";
import RotateIcon from "!vue-svg-loader!@/assets/icons/rotate.svg";

@Component({
  components: {
    Title,
    RotateIcon,
  },
})
export default class Rotate extends Vue {
  @Prop({ type: Function, required: true }) translate: Function;
}
