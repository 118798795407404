import ArrayBuffer from 'nanogl/arraybuffer'
import IndexBuffer from 'nanogl/indexbuffer'

class Geometry {
  drawingBuffer = null

  constructor(gl, vertices, indices) {
    this.gl = gl

    this.vertices = new Float32Array(vertices)
    if (indices) this.indices = new Uint16Array(indices)

    this.allocate()
  }

  // ====== public
  allocate() {
    const gl = this.gl

    this.buffer = new ArrayBuffer(gl)
    this.buffer.data(this.vertices)
    this.drawingBuffer = this.buffer

    if (this.indices) {
      this.ibuffer = new IndexBuffer(gl)
      this.ibuffer.data(this.indices)
      this.drawingBuffer = this.ibuffer
    }
  }

  bind(prg) {
    this.buffer.attribPointer(prg)
    if (this.indices) this.ibuffer.bind()
  }

  attrib(attributeName, size, type) {
    this.buffer.attrib(attributeName, size, type)
  }

  render() {
    if (this.drawingBuffer) {
      this.drawingBuffer[this.drawingMethod]()
    }
  }

  destroy() {
    if (this.buffer) this.buffer.dispose()
    if (this.ibuffer) this.ibuffer.dispose()
  }

  // ------ getters/setters
  get drawingMethod() {
    return 'drawTriangles'
  }
}

export default Geometry
