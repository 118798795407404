import Node from "nanogl-node";
import Camera from "nanogl-camera";
import GLState from "nanogl-state";
import GLConfig from "nanogl-state/config";
import PerspectiveLens from "nanogl-camera/perspective-lens";
import { vec3 } from "gl-matrix";

import fps from "@/store/modules/fps";
import PostProcess from "../PostProcess";
import VideoController from "./Video/controller";
import { FpsSampler } from "@/webgl/Time";

const FORWARD = vec3.create();
vec3.set(FORWARD, 0, 0, -1);

export default class MainScene {
  ispostprocessing = false;

  gl: WebGLRenderingContext
  cfg: GLConfig
  root: Node
  width: number
  height: number
  camera: Camera<PerspectiveLens>
  wrapper: HTMLElement
  glstate: GLState
  pixelRatio: number
  fpsSampler: FpsSampler
  postProcess: PostProcess
  videoController: VideoController

  constructor(gl, opts = { ispostprocessing: false, pixelRatio: 1, wrapper: null }) {
    this.ispostprocessing = opts.ispostprocessing

    this.gl = gl;
    this.wrapper = opts.wrapper

    this.glstate = new GLState(this.gl);
    this.cfg = GLState.config();
    this.cfg.enableCullface(true);

    this.camera = this.makeCamera();

    this.pixelRatio = opts.pixelRatio

    this.fpsSampler = new FpsSampler(10)

    this.root = new Node();
    this.root.add(this.camera);

    if (this.ispostprocessing) {
      this.postProcess = new PostProcess(this);
    }

    this.setVideo()
  }

  setVideo() {
    this.videoController = new VideoController(this.gl, {
      camera: this.camera,
      wrapper: this.wrapper,
      pixelRatio: this.pixelRatio
    });
    this.camera.lookAt(this.videoController.video.node.position);
    this.root.add(this.videoController.video.node)
  }

  resize(width, height, pixelRatio) {
    this.width = width;
    this.height = height;
    this.videoController.resize(pixelRatio)
  }

  render(dt) {
    this.root.updateWorldMatrix()

    this.camera.updateWorldMatrix();
    this.camera.updateViewProjectionMatrix(this.width, this.height);

    const post = this.postProcess;
    if (this.ispostprocessing) {
      this.glstate.apply();
      post.post.preRender(this.width, this.height);
      post.post.bindColor();
    }

    this.glstate.now(this.cfg);

    this.videoController.render()

    this.glstate.apply();
    if (this.ispostprocessing) post.post.render();

    this.fpsSampler.sampleFrame()
    fps.setValue(this.fpsSampler.fps)
  }

  makeCamera() {
    const camera = new Camera(new PerspectiveLens());
    camera.lens.setAutoFov((60.0 / 180) * Math.PI); //80
    camera.lens.near = 0.1;
    camera.lens.far = 100;

    camera.x = 0.0;
    camera.y = 0.0;
    camera.z = 0.0;

    camera.lookAt(FORWARD);

    return camera;
  }

  dispose() { }
}
